/* eslint-disable no-underscore-dangle */
import VueGtm from '@gtm-support/vue2-gtm';
import Vue from 'vue';
import router from '../../router';

Vue.use(VueGtm, {
  // id: 'GTM-MT8J47J',
  id: process.env.VUE_APP_GTM,
  defer: false, // defaults to false. Script can be set to `defer` to increase page-load-time at the cost of less accurate results (in case visitor leaves before script is loaded, which is unlikely but possible)
  enabled: true, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
  debug: true, // Whether or not display
  loadScript: true, // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
  vueRouter: router, // Pass the router instance to automatically sync with router (optional)
  trackOnNextTick: false, // Whether or not call trackView in Vue.nextTick
});
